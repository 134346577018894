<template>
  <v-container class="price-list-router-container">
    Router
  </v-container>
</template>

<script>
export default {
  name: "PriceListRouter",
  components: { },

  data: () => ({
  }),
  mounted() {
    this.$router.push("cjenik-2024");
  }
};
</script>

<style>
.price-list-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: auto;
  background: url(../assets/background.png);
  background-position: top;
  background-repeat: repeat-y;
}
</style>
