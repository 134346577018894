import { createApp } from "vue";
import { createRouter, createWebHistory } from "vue-router";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
import "vuetify/lib/styles/main.css";
import "aos/dist/aos.css";

import BioPage from "./components/BioPage.vue";
import NotFound from "./components/NotFound.vue";
import RuleBook from "./components/RuleBook.vue";
import PriceList from "./components/PriceList.vue";
import GalleryPage from "./components/GalleryPage.vue";
import ContactPage from "./components/ContactPage.vue";
import ThankYouPage from "./components/ThankYouPage.vue";
import HomeComponent from "./components/HomeComponent.vue";
import PriceListSmall from "./components/PriceListSmall.vue";
import PriceListRouter from "./components/PriceListRouter.vue";

loadFonts();

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: "/", name: "HomeComponent", component: HomeComponent },
    { path: "/bio", name: "Bio", component: BioPage },
    { path: "/galerija", name: "Gallery", component: GalleryPage },
    { path: "/kontakt", name: "Contact", component: ContactPage },
    { path: "/pravilnik", name: "RuleBook", component: RuleBook },
    { path: "/cjenik", name: "PriceListSmall", component: PriceListSmall },
    { path: "/cjenik-08-22", name: "PriceListRouter", component: PriceListRouter },
    { path: "/cjenik-2024", name: "PriceList", component: PriceList },
    { path: "/hvala", name: "ThankYouPage", component: ThankYouPage },
    { path: "/:pathMatch(.*)*", name: "NotFound", component: NotFound },
  ],
});

createApp(App).use(vuetify).use(router).mount("#app");
