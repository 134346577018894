<template>
  <v-row v-if="displayMobileMenu" class="top-nav-mobile-menu-container">
    <div @click="toggleMobileMenu" class="top-nav-mobile-main-element">
      <v-icon>mdi-menu</v-icon>
    </div>
    <div
      @click="navHomeClick"
      id="mobileMenuElement01"
      class="top-nav-mobile-element"
    >
      Početna
    </div>
    <div
      @click="navPriceClick"
      id="mobileMenuElement02"
      class="top-nav-mobile-element"
    >
      Cjenik
    </div>
    <div
      @click="navRulesClick"
      id="mobileMenuElement06"
      class="top-nav-mobile-element"
    >
      Pravilnik
    </div>
    <div
      @click="navBioClick"
      id="mobileMenuElement03"
      class="top-nav-mobile-element"
    >
      Bio
    </div>
    <div
      @click="navGalleryClick"
      id="mobileMenuElement04"
      class="top-nav-mobile-element"
    >
      Galerija
    </div>
    <div
      @click="navContactClick"
      id="mobileMenuElement05"
      class="top-nav-mobile-element"
    >
      Kontakt
    </div>
  </v-row>
  <v-row v-else id="topNavMenuContainer" class="top-nav-menu-container">
    <div @click="navHomeClick" class="top-nav-element">Početna</div>
    <div @click="navPriceClick" class="top-nav-element">Cjenik</div>
    <div @click="navRulesClick" class="top-nav-element">Pravilnik</div>
    <div @click="navBioClick" class="top-nav-element">Bio</div>
    <div @click="navGalleryClick" class="top-nav-element">Galerija</div>
    <div @click="navContactClick" class="top-nav-element">Kontakt</div>
  </v-row>
</template>

<script>
export default {
  name: "NavBar",

  data: () => ({
    displayMobileMenu: false,
    windowHeight: window.innerHeight,
  }),
  methods: {
    navHomeClick() {
      this.$router.push("/");
    },
    navPriceClick() {
      this.$router.push("cjenik");
    },
    navBioClick() {
      this.$router.push("bio");
    },
    navGalleryClick() {
      this.$router.push("galerija");
    },
    navContactClick() {
      this.$router.push("kontakt");
    },
    navRulesClick() {
      this.$router.push("pravilnik");
    },
    onResize() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth < 500) {
        this.displayMobileMenu = true;
      } else {
        this.displayMobileMenu = false;
      }
    },
    toggleMobileMenu() {
      let height = document.getElementById("mobileMenuElement01").clientHeight;
      if (height == 0) {
        document.getElementById("mobileMenuElement01").style.height = "30px";
        document.getElementById("mobileMenuElement02").style.height = "30px";
        document.getElementById("mobileMenuElement03").style.height = "30px";
        document.getElementById("mobileMenuElement04").style.height = "30px";
        document.getElementById("mobileMenuElement05").style.height = "30px";
        document.getElementById("mobileMenuElement06").style.height = "30px";
      } else {
        document.getElementById("mobileMenuElement01").style.height = "0px";
        document.getElementById("mobileMenuElement02").style.height = "0px";
        document.getElementById("mobileMenuElement03").style.height = "0px";
        document.getElementById("mobileMenuElement04").style.height = "0px";
        document.getElementById("mobileMenuElement05").style.height = "0px";
        document.getElementById("mobileMenuElement06").style.height = "0px";
      }
    },
  },
  mounted() {
    if (window.innerWidth < 500) {
      this.displayMobileMenu = true;
    } else {
      this.displayMobileMenu = false;
    }
    window.addEventListener("resize", this.onResize);
  },
};
</script>

<style>
.top-nav-menu-container {
  padding-inline: 10px;
  margin-block: 0px;
  margin-bottom: 20px;
  display: block;
}

.top-nav-element {
  font-size: 18px;
  color: #f481a6;
  text-align: center;
  width: 16.66%;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
}

.top-nav-element:hover {
  border: 1px solid rgb(255, 255, 255);
  background: rgba(255, 255, 255, 0.135);
  color: #a9697d;
}

.top-nav-mobile-main-element {
  overflow: hidden;
}
.top-nav-mobile-main-element {
  padding-block: 10px;
  font-size: 18px;
  color: #f481a6;
  text-align: center;
  width: 100%;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
}
.top-nav-mobile-element {
  overflow: hidden;
  height: 0px;
  font-size: 18px;
  color: #f481a6;
  text-align: center;
  width: 100%;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  transition: height 200ms;
}
</style>
