<template>
  <v-container class="contact-container">
    <BackToTop></BackToTop>
    <v-row class="text-center">
      <v-col cols="12" class="contact-global-content-container">
        <NavBar></NavBar>
        <v-row>
          <v-col cols="12" lg="12" class="contact-title-name-container">
            Kontakt
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-row>
              <v-col
                class="contact-entry"
                cols="12"
                lg="3"
                @click="locationClick"
                data-aos="fade-up"
                data-aos-duration="300"
                ><v-img
                  class="social-media-logo-image"
                  src="../assets/location_logo.png"
                  width="70"
                >
                </v-img
                ><span class="contact-sub"> Jagićeva 7</span></v-col
              >
              <v-col
                class="contact-entry"
                cols="12"
                lg="3"
                @click="whatsappClick"
                data-aos="fade-up"
                data-aos-duration="500"
                ><v-img
                  class="social-media-logo-image"
                  src="../assets/phone_logo.png"
                  width="70"
                >
                </v-img
                ><span class="contact-sub">0996479605</span></v-col
              >
              <v-col
                class="contact-entry"
                cols="12"
                lg="3"
                @click="instagramClick"
                data-aos="fade-up"
                data-aos-duration="700"
                ><v-img
                  class="social-media-logo-image"
                  src="../assets/instagram_logo.png"
                  width="70"
                >
                </v-img>
                <span class="contact-sub">@migoto.zg</span></v-col
              >
              <v-col
                class="contact-entry"
                cols="12"
                lg="3"
                @click="facebookClick"
                data-aos="fade-up"
                data-aos-duration="900"
                ><v-img
                  class="social-media-logo-image"
                  src="../assets/facebook_logo.png"
                  width="70"
                ></v-img>
                <span class="contact-sub">Salon Migoto</span></v-col
              >
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import NavBar from "./NavBar.vue";
import BackToTop from "./BackToTop.vue";
export default {
  name: "ContactPage",
  components: { NavBar, BackToTop },
  data: () => ({}),
  methods: {
    whatsappClick() {
      window.open("https://wa.me/00385996479605");
    },
    facebookClick() {
      window.open("https://www.facebook.com/migoto.zg");
    },
    instagramClick() {
      window.open("https://www.instagram.com/migoto.zg/");
    },
    locationClick() {
      window.open("https://goo.gl/maps/1xTUUHtMKXLnHB6i6");
    },
  },
};
</script>

<style>
@font-face {
  font-family: "JandysDua";
  src: local("JandysDua"), url(../fonts/Jandys-dua.otf) format("truetype");
}

.contact-content-container {
  font-size: 18px;
}

.contact-content-container p {
  margin-top: 10px;
}

.contact-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: auto;
  background: url(../assets/background.png);
  background-position: center;
  background-repeat: repeat-y;
}

.contact-global-content-container {
  margin-block: 40px;
  margin-inline: 25% !important;
  font-family: "montserrat";
  flex: unset !important;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(4.9px);
  -webkit-backdrop-filter: blur(4.9px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  color: rgb(102, 102, 102);
  font-size: 24px;
}

.contact-title-name-container {
  font-family: "JandysDua";
  text-align: left;
  font-size: 100px;
  color: #f481a6;
  filter: drop-shadow(0px 0px 5px #ffffff);
}

.contact-sub {
  display: none;
  color: #f481a6;
  line-height: 50px;
}

.contact-entry {
  cursor: pointer;
  margin-bottom: 40px;
}

.contact-entry:hover .contact-sub {
  color: #f481a6;
}

.contact-entry:hover .social-media-logo-image {
  filter: drop-shadow(0px 0px 5px #ff004c);
}

.social-media-logo-image {
  margin-inline: auto;
  filter: drop-shadow(0px 0px 1px #ffffff);
}

@media only screen and (max-width: 991px) {
  .contact-global-content-container {
    margin-inline: 2% !important;
    font-size: 14px;
  }

  .contact-title-name-container {
    font-size: 50px;
  }
}
</style>
