<template>
  <v-container class="gallery-list-container">
    <BackToTop></BackToTop>
    <v-row class="text-center">
      <v-col cols="12" class="gallery-global-content-container">
        <NavBar></NavBar>
        <v-row>
          <v-col cols="12" lg="12" class="gallery-title-name-container">
            Galerija
          </v-col>
        </v-row>
        <v-row class="gallery-content-container" v-if="initialLoad">
          <v-col cols="6" class="gallery-left-column">
            <div
              data-aos="fade-right"
              data-aos-duration="900"
              v-for="photo in firstColumn"
              :key="photo.name"
            >
              <v-img
                class="gallery-image"
                :src="require(`@/assets/${photo.filename}`)"
              ></v-img>
            </div>
          </v-col>
          <v-col cols="6" class="gallery-right-column"
            ><div
              v-for="photo in secondColumn"
              :key="photo.name"
              data-aos="fade-left"
              data-aos-duration="900"
            >
              <v-img
                class="gallery-image"
                :src="require(`@/assets/${photo.filename}`)"
              ></v-img>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import NavBar from "./NavBar.vue";
import BackToTop from "./BackToTop.vue";
export default {
  name: "GalleryPage",
  components: { NavBar, BackToTop },
  data: () => ({
    firstColumn: [
      { name: 1, filename: "1.png" },
      { name: 14, filename: "14.png" },
      { name: 16, filename: "16.png" },
      { name: 12, filename: "12.png" },
      { name: 5, filename: "5.png" },
      { name: 11, filename: "11.png" },
      { name: 2, filename: "2.png" },
      { name: 8, filename: "8.png" },
      { name: 9, filename: "9.png" },
    ],
    secondColumn: [
      { name: 15, filename: "15.png" },
      { name: 10, filename: "10.png" },
      { name: 6, filename: "6.png" },
      { name: 4, filename: "4.png" },
      { name: 13, filename: "13.png" },
      { name: 3, filename: "3.png" },
      { name: 7, filename: "7.png" },
      { name: 17, filename: "17.png" },
    ],
    initialLoad: false,
  }),
  methods: {},
  mounted() {
    this.initialLoad = true;
  },
};
</script>

<style>
@font-face {
  font-family: "JandysDua";
  src: local("JandysDua"), url(../fonts/Jandys-dua.otf) format("truetype");
}

.gallery-content-container {
  font-size: 18px;
}

.gallery-content-container p {
  margin-top: 10px;
}

.gallery-list-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: auto;
  min-height: 100vh;
  background: url(../assets/background.png);
  background-position: center;
  background-repeat: repeat-y;
  overflow: hidden;
}

.gallery-global-content-container {
  margin-block: 40px;
  margin-inline: 25% !important;
  font-family: "montserrat";
  flex: unset !important;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(4.9px);
  -webkit-backdrop-filter: blur(4.9px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  color: rgb(102, 102, 102);
  font-size: 24px;
}

.gallery-title-name-container {
  margin-top: 20px;
  padding: 30px;
  font-family: "JandysDua";
  text-align: left;
  font-size: 100px;
  color: #f481a6;
  filter: drop-shadow(0px 0px 5px #ffffff);
}

.gallery-left-column {
  padding-right: 0px !important;
  margin-inline: 0px !important;
  margin-top: -30px;
}

.gallery-right-column {
  padding-left: 0px !important;
  margin-inline: 0px;
  margin-top: 165px;
}

@media only screen and (max-width: 991px) {
  .gallery-global-content-container {
    margin-inline: 2% !important;
    font-size: 14px;
  }

  .gallery-title-name-container {
    font-size: 50px;
  }

  .gallery-right-column {
    padding-left: 0px !important;
    margin-inline: 0px;
    margin-top: 70px;
  }
}
</style>
