<template>
  <v-row
    v-if="displayButton"
    @click="toTopClick"
    id="backToTopButton"
    class="back-to-top-container"
  >
    Na vrh
  </v-row>
</template>

<script>
export default {
  name: "BackToTop",

  data: () => ({
    displayButton: false,
  }),
  computed: {},
  methods: {
    toTopClick() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },
  mounted() {
    var element1 = document.getElementById("topNavMenuContainer");
    var element2 = document.getElementById("priceTitleFull");
    let vm = this;
    window.addEventListener("scroll", function () {
      if (element1 !== undefined && element1 !== null) {
        if (window.scrollY > element1.offsetTop + element1.offsetHeight) {
          if (vm.displayButton == false) {
            vm.displayButton = true;
          }
        } else {
          if (vm.displayButton == true) {
            vm.displayButton = false;
          }
        }
      }
      if (element2 !== undefined && element2 !== null) {
        if (window.scrollY > element2.offsetTop + element2.offsetHeight) {
          if (vm.displayButton == false) {
            vm.displayButton = true;
          }
        } else {
          if (vm.displayButton == true) {
            vm.displayButton = false;
          }
        }
      }
    });
  },
};
</script>

<style>
.back-to-top-container {
  z-index: 99;
  position: fixed;
  bottom: 50px;
  right: 50px;
  height: 70px;
  line-height: 70px;
  width: 70px;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(4.9px);
  -webkit-backdrop-filter: blur(4.9px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  justify-content: center;
  font-size: 13px;
  cursor: pointer;
  color: #6f4150;
}

.back-to-top-container:hover {
  background: rgba(255, 255, 255, 0.435);
  color: #f481a6;
}
</style>
