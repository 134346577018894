<template>
  <v-container class="price-list-container">
    <BackToTop></BackToTop>
    <v-row class="text-center">
      <v-col cols="12" class="price-content-container-full">
        <v-row>
          <v-col
            v-if="displayPriceList"
            cols="8"
            lg="4"
            id="priceTitleFull"
            class="price-title-name-container"
          >
            {{ displayTitle }}
          </v-col>
          <v-col
            v-if="displayPriceList"
            cols="4"
            lg="4"
            class="price-title-button-container"
          >
            <div class="price-title-button" @click="toggleContent">
              {{ buttonText }}
            </div>
          </v-col>
          <v-col
            v-if="!displayPriceList"
            cols="4"
            lg="4"
            class="price-title-button-container"
          >
            <div class="price-title-button" @click="toggleContent">
              {{ buttonText }}
            </div>
          </v-col>
          <v-col
            v-if="!displayPriceList"
            cols="6"
            id="priceTitleFull"
            class="price-title-name-container"
          >
            {{ displayTitle }}
          </v-col>
        </v-row>
        <div v-if="displayPriceList" class="price-list-content-container">
          <v-row v-for="entry in cjenik" :key="entry.id">
            <v-col cols="7" class="pricelist-name-container">
              {{ entry.name }}
            </v-col>
            <v-col cols="2" class="pricelist-category-container">
              {{ entry.category }}
            </v-col>
            <v-col cols="3" class="pricelist-price-container">
              <span >
                {{ entry.priceEur }}
                <span style="color: rgb(102, 102, 102); font-weight: 300"
                  >€</span
                >
              </span>
            </v-col>
          </v-row>
          <v-row class="oil-container">
            <v-col cols="12" class="oil-title"
              >Dostupne arome ulja za kutikulu:</v-col
            >
            <v-col
              cols="12"
              class="oil-entry"
              v-for="ulje in ulja"
              :key="ulje.name"
              >{{ ulje.name }}</v-col
            >
          </v-row>
        </div>
        <div v-if="!displayPriceList">
          <v-row>
            <v-col cols="12" class="rules-content-container">
              <p class="bold-entry">drage sadašnje i buduće klijentice</p>
              <p class="bold-entry">
                ovdje se nalaze pravila ponašanja i objašnjenja za njih te neke opće informacije o tome što se očekuje i ne očekuje od vas kao klijenata 😊
              </p>
              <p>
                * sve nove i povremene klijentice obavezno uplaćuju akontaciju od 50% iznosa usluge prilikom rezervacije prvog termina. na terminu se zatim plaća ostalih 50%, ništa više. možete to gledati kao podjelu naplate na dva dijela, a ja se tako osiguravam na to da ćete se pojaviti na termin.<br>
                ukoliko je termin otkazan bar 24h unaprijed, akontacija se vraća ili koristi za rezervaciju novog termina. u protivnom je nepovratna.
              </p>
              <p>
                * termin je potrebno otkazati najmanje 24 sata unaprijed, kako bi se povećale šanse da ga popunim. ukoliko se termin ne otkaže 24 sata unaprijed, 50% termina se naplaćuje na sljedećem dolasku.
              </p>
              <p>
                * u slučaju nepojavljivanja na termin bez javljanja (zaboravila sam, zaspala sam, itd.), propušteni termin se naplaćuje u potpunosti na sljedećem dolasku<br>
                ukoliko trebate doći prvi put i ne pojavite se bez javljanja, više nećete moći dobiti termin.
              </p>
              <p>
                * kašnjenje nije dozvoljeno. tolerira se do 5 minuta kašnjenja bez nadoplate, nakon 5 minuta uz nadoplatu, a nakon 10 minuta termin se otkazuje kako ne bismo morali micati cijeli ostatak rasporeda.
              </p>
              <p>
                * dolazak ranije nije dozvoljen. tolerira se do 5 minuta ranjenja.<br>
                ukoliko dođete više od 5 minuta ranije, ljubazno vas molim da pričekate svoj termin. Raniji dolazak mi zaista stvara pritisak, a rad pod pritiskom stvarno nije nešto što volim prakticirati.
              </p>
              <p>
                * kompleksniji setovi ili setovi koji se razlikuju od onog što radite inače potencijalno traju duže i dodatno se naplaćuju. ukoliko želite takav set (bilo što da nisu samo jednobojni nokti), ljubazno vas molim da mi to unaprijed najavite u poruci kako bih znala bolje organizirati vrijeme, a nekad čak i materijal. ukoliko mi ne pošaljete dizajn koji želite, možda neću moći ispuniti vaše želje i dobit ćete ono što je vremenski izvedivo.
              </p>
              <p>
                * tuđi rad se tretira i naplaćuje ovisno o njegovom stanju; od obične cijene korekcije preko nadoplate od 10 eura za neadekvatan rad, do skidanja svog materijala i ponovne ugradnje uz naplatu dotičnih usluga.
              </p>
              <p>
                * idealno vrijeme za nadopunu je nakon 3-4 tjedna od prošlog termina. radovi stari 5 ili više tjedana (točno u dan) dodatno se naplaćuju, odnosno naplaćuju se po cijeni ugradnje.<br>
                5 tjedana je krajnja granica i to ukoliko se stvarno dogodi da niste mogle doći prije zbog bolesti ili neke druge ozbiljne situacije, ili vas ja nisam mogla prije primiti.
              </p>
              <p>
                * za vrijeme tretmana vas MOLIM da za stolom sjedite ravno, mirno i pristojno, okrenute prema meni, s ravno postavljenim i apsolutno opuštenim rukama, ne cupkate na stolici, ne vrtite se lijevo-desno, ne dirate kožu i ne prelazite prstima po noktima, ne češete se, ne dirate kosu, odjeću, torbu, džepove itd., ne dižete i ne okrećete ruke kako mislite da će meni „olakšati“ jer mi apsolutno ne olakšava. također vas molim da ne radite nagle pokrete, da budete svjesne toga da iako je ruka s kojom radim mirna, ukoliko pomaknete neki drugi dio tijela dok npr. nanosim boju, i ta ruka će se pomaknuti i rad neće biti onakav kakav je trebao biti.<br>
                trebam samo da budete mirne, dobre, opuštene i surađujete te me slušate kad vam dam upute za to kako držati ruku. to će i vama i meni osigurati lakši, brži i bezbolniji posao.
              </p>
              <br><br>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BackToTop from "./BackToTop.vue";
export default {
  name: "PriceList",
  components: { BackToTop },

  data: () => ({
    displayEuro: true,
    displayPriceList: true,
    buttonText: "Pravilnik",
    displayTitle: "Cjenik",
    displayRules: false,
    cjenik: [
      {
        name: "Ugradnja umjetnih noktiju",
        category: "S",
        priceEur: "40.00",
      },
      { name: "", category: "M", priceEur: "45.00" },
      { name: "", category: "L", priceEur: "50.00" },
      { name: "", category: "XL", priceEur: "60.00" },
      {
        name: "Korekcija",
        category: "S",
        priceEur: "30.00",
      },
      { name: "", category: "M", priceEur: "35.00" },
      { name: "", category: "L", priceEur: "40.00" },
      { name: "", category: "XL", priceEur: "45.00" },
      {
        name: "Geliranje prirodnih noktiju",
        category: "S",
        priceEur: "26.00",
      },
      {
        name: "(bez produživanja)",
        category: "M",
        priceEur: "28.00",
      },
      {
        name: "Trajni lak s ojačavanjem apeksa",
        category: "S",
        priceEur: "25.00",
      },
      { name: "", category: "M", priceEur: "27.00" },
      {
        name: "Trajni lak na nogama",
        category: "",
        priceEur: "22.00",
      },
      {
        name: "Popravak na korekciji (po noktu)",
        category: "",
        priceEur: "3.50",
      },
      {
        name: "Popravak van korekcije (po noktu)",
        category: "",
        priceEur: "5.00",
      },
      {
        name: "Kombi manikura",
        category: "",
        priceEur: "15.00",
      },
      {
        name: "Promjena gradivnog gela",
        category: "S",
        priceEur: "4.00",
      },
      { name: "", category: "M", priceEur: "7.00" },
      { name: "", category: "L", priceEur: "10.00" },
      { name: "", category: "XL", priceEur: "12.00" },
      {
        name: "Promjena oblika",
        category: "S",
        priceEur: "4.00",
      },
      { name: "", category: "M", priceEur: "6.00" },
      { name: "", category: "L", priceEur: "8.00" },
      { name: "", category: "XL", priceEur: "10.00" },
      {
        name: "Skidanje gela/trajnog laka",
        category: "",
        priceEur: "15.00",
      },
      {
        name: "Skidanje gela uz novu ugradnju",
        category: "",
        priceEur: "10.00",
      },
      {
        name: "Nadoplata za neadekvatan tuđi rad",
        category: "",
        priceEur: "10.00",
      },
      {
        name: "Nadoplata za trajni lak preko 5 tjedana",
        category: "",
        priceEur: "3.00",
      },
      {
        name: "Ispravljanje kandžastog nokta",
        category: "",
        priceEur: "2.00",
      },
      {
        name: "Crtani french, Baby boomer",
        category: "",
        priceEur: "6.00",
      },
      {
        name: "Baby boomer u boji",
        category: "",
        priceEur: "8.00",
      },
      {
        name: "Korištenje 2 - 5 boja po setu",
        category: "",
        priceEur: "2.00",
      },
      {
        name: "Korištenje 6 - 10 boja po setu",
        category: "",
        priceEur: "5.00",
      },
      {
        name: "Naljepnice, folija, cirkoni, stamping",
        category: "",
        priceEur: "1.00/3.00",
      },
      {
        name: "Chrome/Cat-eye",
        category: "",
        priceEur: "2.00/5.00",
      },
      {
        name: "Ombre (po noktu)",
        category: "",
        priceEur: "3.00",
      },
      {
        name: "Ombre sprej",
        category: "",
        priceEur: "3.00",
      },
      {
        name: "Mramorni efekt (po noktu)",
        category: "",
        priceEur: "1.00/2.00/3.00",
      },
      {
        name: "Crtanje (po noktu)",
        category: "",
        priceEur: "1.00/2.00/3.00",
      },
      {
        name: "Enkapsuliranje",
        category: "",
        priceEur: "2.00/4.00",
      },
    ],
    ulja: [
      { name: "Kokos" },
      { name: "Vanilija" },
      { name: "Ananas" },
      { name: "Jabuka i cimet" },
      { name: "Pita od jabuke" },
      { name: "Naranča" },
      { name: "Banana" },
      { name: "Lubenica" },
      { name: "Pita od oraha" },
      { name: "Karamela" },
      { name: "Malina" },
      { name: "Šumsko voće" },
      { name: "Tropsko voće" },
      { name: "Slatkiši" },
      { name: "Cvijeće" },
    ],
  }),
  computed: {
    switchDisplay() {
      if (this.displayEuro) {
        return "EUR";
      }
      return "HRK";
    },
  },
  methods: {
    toggleContent() {
      this.displayPriceList = !this.displayPriceList;
      this.displayRules = !this.displayRules;
      if (this.displayPriceList) {
        this.buttonText = "Pravilnik";
        this.displayTitle = "Cjenik";
      } else {
        this.buttonText = "Cjenik";
        this.displayTitle = "Pravilnik";
      }
    },
  },
};
</script>

<style>
@font-face {
  font-family: "JandysDua";
  src: local("JandysDua"), url(../fonts/Jandys-dua.otf) format("truetype");
}

.price-list-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  min-height: 100vh;
  background: url(../assets/background.png);
  background-position: top;
  background-repeat: repeat-y;
}

.price-content-container-full {
  margin-block: 40px;
  margin-inline: 25% !important;
  font-family: "montserrat";
  flex: unset !important;
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.3);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(4.9px);
  -webkit-backdrop-filter: blur(4.9px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  color: rgb(102, 102, 102);
  font-size: 20px;
}

.price-title-name-container {
  font-family: "JandysDua";
  text-align: left;
  font-size: 100px;
  color: #f481a6;
  filter: drop-shadow(0px 0px 5px #ffffff);
}

.price-title-switch-container {
  line-height: 100px;
}

.pricelist-name-container {
  text-align: left;
}

.pricelist-category-container {
  text-align: left;
}

.pricelist-price-container {
  color: #f481a6;
  font-weight: 500;
  text-align: left;
}

.currency-switch {
  margin-top: 35px;
}

.currency-switch label {
  color: #f481a6 !important;
  font-weight: bold;
}

.price-title-button-container {
  margin-block: auto;
}

.price-title-button {
  background: rgba(255, 255, 255, 0.3);
  border-radius: 4px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(4.9px);
  -webkit-backdrop-filter: blur(4.9px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  margin-inline: 40px;
  height: 30px;
  cursor: pointer;
  color: #f481a6;
  font-weight: 300;
  font-size: 18px;
}

.price-title-button:hover {
  background: rgba(255, 255, 255, 0.435);
  color: #f481a6;
}

.oil-container {
  text-align: left;
}

.oil-title {
  color: #f481a6;
  padding-top: 20px !important;
  padding-bottom: 0px;
}

.oil-entry {
  margin-block: 0px !important;
  padding-block: 1px !important;
  display: block;
}
@media only screen and (max-width: 991px) {
  .price-content-container-full {
    margin-inline: 2% !important;
    font-size: 14px;
  }

  .price-title-name-container {
    font-size: 50px;
  }

  .pricelist-name-container {
    text-align: left;
  }

  .currency-switch {
    margin-top: 0px;
  }

  .currency-switch label {
    font-size: 12px !important;
    color: #f481a6 !important;
    font-weight: bold;
  }

  .price-title-button {
    margin-inline: 0px;
    line-height: 30px;
  }

  .price-list-container {
    padding-bottom: 100px;
  }
}
</style>
