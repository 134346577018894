<template>
  <v-container class="price-list-small-container">
    <BackToTop></BackToTop>
    <v-row class="text-center">
      <v-col cols="12" class="price-content-container">
        <NavBar></NavBar>
        <v-row>
          <v-col cols="5" lg="8" class="price-title-name-container">
            Cjenik
          </v-col>
        </v-row>
        <v-row v-for="entry in cjenik" :key="entry.id">
          <v-col cols="7" class="pricelist-name-container">
            {{ entry.name }}
          </v-col>
          <v-col cols="2" class="pricelist-category-container">
            {{ entry.category }}
          </v-col>
          <v-col cols="3" class="pricelist-price-container">
            <span>
              {{ entry.priceEur }}
              <span style="color: rgb(102, 102, 102); font-weight: 300">€</span>
            </span>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import NavBar from "./NavBar.vue";
import BackToTop from "./BackToTop.vue";
export default {
  name: "PriceListSmall",
  components: { NavBar, BackToTop },
  data: () => ({
    displayEuro: true,
    cjenik: [
      {
        name: "Ugradnja umjetnih noktiju",
        category: "S",
        priceEur: "40.00",
      },
      { name: "", category: "M", priceEur: "45.00" },
      { name: "", category: "L", priceEur: "50.00" },
      { name: "", category: "XL", priceEur: "60.00" },
      {
        name: "Korekcija",
        category: "S",
        priceEur: "30.00",
      },
      { name: "", category: "M", priceEur: "35.00" },
      { name: "", category: "L", priceEur: "40.00" },
      { name: "", category: "XL", priceEur: "45.00" },
      {
        name: "Geliranje prirodnih noktiju",
        category: "S",
        priceEur: "26.00",
      },
      {
        name: "(bez produživanja)",
        category: "M",
        priceEur: "28.00",
      },
      {
        name: "Trajni lak s ojačavanjem apeksa",
        category: "S",
        priceEur: "25.00",
      },
      { name: "", category: "M", priceEur: "27.00" },
      {
        name: "Trajni lak na nogama",
        category: "",
        priceEur: "22.00",
      },
    ],
  }),
  computed: {
    switchDisplay() {
      if (this.displayEuro) {
        return "EUR";
      }
      return "HRK";
    },
  },
  methods: {},
};
</script>

<style>
@font-face {
  font-family: "JandysDua";
  src: local("JandysDua"), url(../fonts/Jandys-dua.otf) format("truetype");
}
@font-face {
  font-family: "SouthAustralia";
  src: local("SouthAustralia"),
    url(../fonts/South-Australia.ttf) format("truetype");
}

.price-list-small-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  background: url(../assets/background.png);
  background-position: top;
  background-size: cover;
  background-repeat: repeat;
}

.price-content-container {
  margin-top: 40px;
  margin-bottom: 120px;
  margin-inline: 25% !important;
  font-family: "montserrat";
  flex: unset !important;
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.3);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(4.9px);
  -webkit-backdrop-filter: blur(4.9px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  color: rgb(102, 102, 102);
  font-size: 24px;
}

.price-title-name-container {
  font-family: "JandysDua";
  text-align: left;
  font-size: 100px;
  color: #f481a6;
  filter: drop-shadow(0px 0px 5px #ffffff);
}

.price-title-switch-container {
  line-height: 100px;
}

.pricelist-name-container {
  text-align: left;
}

.pricelist-category-container {
  text-align: left;
}

.pricelist-price-container {
  padding-right: 20px;
  color: #f481a6;
  font-weight: 500;
  text-align: right;
}

.currency-switch {
  margin-right: 30px;
  margin-top: 100px;
  display: flex;
  justify-content: end;
}

.currency-switch label {
  opacity: 1 !important;
  color: #f481a6 !important;
  font-weight: bold;
}

@media only screen and (max-width: 991px) {
  .price-content-container {
    margin-inline: 2% !important;
    font-size: 14px;
  }

  .price-title-name-container {
    font-size: 50px;
  }

  .pricelist-name-container {
    text-align: left;
  }

  .currency-switch {
    margin-top: 0px;
  }

  .currency-switch label {
    font-size: 12px !important;
    color: #f481a6 !important;
    font-weight: bold;
  }
}
</style>
