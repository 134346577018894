<template>
  <v-app>
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import AOS from "aos";
export default {
  name: "App",

  components: {},

  data: () => ({
    //
  }),
  mounted() {
    AOS.init();
  },
};
</script>

<style>
@font-face {
  font-family: "StruckBase";
  src: local("StruckBase"),
    url(./fonts/StruckBase-Regular.otf) format("truetype");
}
.v-container {
  max-width: unset !important;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: #d8a9b9;
}

::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: #f583a7;
}
</style>
