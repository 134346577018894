<template>
  <v-container class="rules-list-container">
    <BackToTop></BackToTop>
    <v-row class="text-center">
      <v-col cols="12" class="rules-global-content-container">
        <NavBar></NavBar>
        <v-row>
          <v-col cols="12" lg="12" class="rules-title-name-container">
            Pravilnik
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="rules-content-container">
            <p class="bold-entry">drage sadašnje i buduće klijentice</p>
              <p class="bold-entry">
                ovdje se nalaze pravila ponašanja i objašnjenja za njih te neke opće informacije o tome što se očekuje i ne očekuje od vas kao klijenata 😊
              </p>
              <p>
                * sve nove i povremene klijentice obavezno uplaćuju akontaciju od 50% iznosa usluge prilikom rezervacije prvog termina. na terminu se zatim plaća ostalih 50%, ništa više. možete to gledati kao podjelu naplate na dva dijela, a ja se tako osiguravam na to da ćete se pojaviti na termin.<br>
                ukoliko je termin otkazan bar 24h unaprijed, akontacija se vraća ili koristi za rezervaciju novog termina. u protivnom je nepovratna.
              </p>
              <p>
                * termin je potrebno otkazati najmanje 24 sata unaprijed, kako bi se povećale šanse da ga popunim. ukoliko se termin ne otkaže 24 sata unaprijed, 50% termina se naplaćuje na sljedećem dolasku.
              </p>
              <p>
                * u slučaju nepojavljivanja na termin bez javljanja (zaboravila sam, zaspala sam, itd.), propušteni termin se naplaćuje u potpunosti na sljedećem dolasku<br>
                ukoliko trebate doći prvi put i ne pojavite se bez javljanja, više nećete moći dobiti termin.
              </p>
              <p>
                * kašnjenje nije dozvoljeno. tolerira se do 5 minuta kašnjenja bez nadoplate, nakon 5 minuta uz nadoplatu, a nakon 10 minuta termin se otkazuje kako ne bismo morali micati cijeli ostatak rasporeda.
              </p>
              <p>
                * dolazak ranije nije dozvoljen. tolerira se do 5 minuta ranjenja.<br>
                ukoliko dođete više od 5 minuta ranije, ljubazno vas molim da pričekate svoj termin. Raniji dolazak mi zaista stvara pritisak, a rad pod pritiskom stvarno nije nešto što volim prakticirati.
              </p>
              <p>
                * kompleksniji setovi ili setovi koji se razlikuju od onog što radite inače potencijalno traju duže i dodatno se naplaćuju. ukoliko želite takav set (bilo što da nisu samo jednobojni nokti), ljubazno vas molim da mi to unaprijed najavite u poruci kako bih znala bolje organizirati vrijeme, a nekad čak i materijal. ukoliko mi ne pošaljete dizajn koji želite, možda neću moći ispuniti vaše želje i dobit ćete ono što je vremenski izvedivo.
              </p>
              <p>
                * tuđi rad se tretira i naplaćuje ovisno o njegovom stanju; od obične cijene korekcije preko nadoplate od 10 eura za neadekvatan rad, do skidanja svog materijala i ponovne ugradnje uz naplatu dotičnih usluga.
              </p>
              <p>
                * idealno vrijeme za nadopunu je nakon 3-4 tjedna od prošlog termina. radovi stari 5 ili više tjedana (točno u dan) dodatno se naplaćuju, odnosno naplaćuju se po cijeni ugradnje.<br>
                5 tjedana je krajnja granica i to ukoliko se stvarno dogodi da niste mogle doći prije zbog bolesti ili neke druge ozbiljne situacije, ili vas ja nisam mogla prije primiti.
              </p>
              <p>
                * za vrijeme tretmana vas MOLIM da za stolom sjedite ravno, mirno i pristojno, okrenute prema meni, s ravno postavljenim i apsolutno opuštenim rukama, ne cupkate na stolici, ne vrtite se lijevo-desno, ne dirate kožu i ne prelazite prstima po noktima, ne češete se, ne dirate kosu, odjeću, torbu, džepove itd., ne dižete i ne okrećete ruke kako mislite da će meni „olakšati“ jer mi apsolutno ne olakšava. također vas molim da ne radite nagle pokrete, da budete svjesne toga da iako je ruka s kojom radim mirna, ukoliko pomaknete neki drugi dio tijela dok npr. nanosim boju, i ta ruka će se pomaknuti i rad neće biti onakav kakav je trebao biti.<br>
                trebam samo da budete mirne, dobre, opuštene i surađujete te me slušate kad vam dam upute za to kako držati ruku. to će i vama i meni osigurati lakši, brži i bezbolniji posao.
              </p>
              <br><br>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import NavBar from "./NavBar.vue";
import BackToTop from "./BackToTop.vue";
export default {
  name: "RuleBook",
  components: { NavBar, BackToTop },
  data: () => ({}),
  methods: {},
};
</script>

<style>
@font-face {
  font-family: "JandysDua";
  src: local("JandysDua"), url(../fonts/Jandys-dua.otf) format("truetype");
}
@font-face {
  font-family: "SouthAustralia";
  src: local("SouthAustralia"),
    url(../fonts/South-Australia.ttf) format("truetype");
}

.rules-content-container {
  font-size: 18px;
}

.rules-content-container p {
  margin-top: 10px;
}

.rules-list-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: auto;
  background: url(../assets/background.png);
  background-position: top;
  background-repeat: repeat-y;
}

.rules-global-content-container {
  margin-block: 40px;
  margin-inline: 25% !important;
  font-family: "montserrat";
  flex: unset !important;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(4.9px);
  -webkit-backdrop-filter: blur(4.9px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  color: rgb(102, 102, 102);
  font-size: 24px;
}

.rules-title-name-container {
  font-family: "JandysDua";
  text-align: left;
  font-size: 100px;
  color: #f481a6;
  filter: drop-shadow(0px 0px 5px #ffffff);
}

.bold-entry {
  font-weight: 600;
}

@media only screen and (max-width: 991px) {
  .rules-global-content-container {
    margin-inline: 2% !important;
    font-size: 14px;
  }

  .rules-title-name-container {
    font-size: 50px;
  }
}
</style>
