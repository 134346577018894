<template>
  <v-container class="bio-container">
    <BackToTop></BackToTop>
    <v-row class="text-center">
      <v-col cols="12" class="bio-global-content-container">
        <NavBar></NavBar>
        <v-row>
          <v-col cols="12" lg="12" class="bio-title-name-container">
            Bio
          </v-col>
        </v-row>
        <v-row
          class="bio-content-container"
          data-aos="fade-right"
          data-aos-duration="900"
        >
          <v-col cols="12" lg="5" class="bio-image-container"
            ><v-img class="bio-image" src="../assets/bio_02.png" height="500">
            </v-img>
          </v-col>
          <v-col cols="12" lg="7" class="bio-text-container-01">
            <p class="bio-location-title">Salon Migoto</p>
            <p>
              <span class="bio-sub-title">usluge: </span>umjetni nokti, trajni
              lak, lash lift, brow lift
            </p>
            <p>
              <span class="bio-sub-title">datum osnivanja: </span>20.08.2021.
            </p>
            <p>
              <span class="bio-sub-title">značenje: </span>(na japanskom)
              spektakl, prizor, (nešto što je) divno, veličanstveno, vrijedno
              gledanja, odlično, izvanredno, prekrasno. ideja za ime potiče od
              ljubavi prema azijskim jezicima i kulturama kao i željom da se
              imenom razlikuje od mase
            </p>
          </v-col>
        </v-row>
        <v-row
          class="bio-content-container"
          data-aos="fade-left"
          data-aos-duration="900"
        >
          <v-col
            cols="12"
            lg="7"
            order="2"
            order-lg="1"
            class="bio-text-container-02"
          >
            <br />
            <p>
              <span class="bio-location-title">Jana Kontrec </span>(nail
              tehničar i vlasnica salona)
            </p>
            <p>rođena 08.11.1992. u Zagrebu.</p>
            <p>2011. završila Prvu privatnu gimnaziju.</p>
            <p>Studirala na ZŠEM-u i FFZG-u.</p>
            <p>
              2016. uz fakultet započinje rad s umjetnim noktima. Pronalazi se
              ovdje sve više i kroz godine rada uz fakultetska predavanja i
              kaotičan raspored, dolazi do osobnog prosvjetljenja te se 2020.
              odlučuje u potpunosti posvetiti ovom poslu.
            </p>
            <p>2021. otvara salon i stavlja pečat na tu odluku.</p>
            <p>U međuvremenu se obučava</p>
            <p>i za tretmane za trepavice i obrve.</p>
            <p>
              Od početka bavljenja noktima, cijelo vrijeme pohađa razne
              edukacije i konstantno se obrazuje,
            </p>
            <p>usavršava i prati trendove.</p>
            <p>Završila edukacije kod nekih od najjačih imena</p>
            <p>na sceni beauty industrije;</p>
            <p>Jana Nails, Crystal Nails, Anesa Beauty, Martina Kallos …</p>
            <p>
              Radi po motu „Kvaliteta preko kvantitete“ i uzima manji broj
              klijentica na dnevnoj bazi kako bi se svakoj od njih u potpunosti
              mogla maksimalno posvetiti i dostaviti kako estetski, tako i
              tehnički zadovoljavajuće radove.
            </p>
            <p>
              Pazi na svaki detalj i nerijetko od novih klijentica dobije
              komentar kako je jako posvećena, precizna i da se vidi da radi s
              ljubavlju.
            </p>
          </v-col>
          <v-col
            cols="12"
            lg="5"
            class="bio-image-container"
            order="1"
            order-lg="2"
            ><v-img class="bio-image" src="../assets/bio_01.png" height="500">
            </v-img>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import NavBar from "./NavBar.vue";
import BackToTop from "./BackToTop.vue";
export default {
  name: "BioPage",
  components: { NavBar, BackToTop },
  data: () => ({}),
  methods: {},
};
</script>

<style>
@font-face {
  font-family: "JandysDua";
  src: local("JandysDua"), url(../fonts/Jandys-dua.otf) format("truetype");
}

.not-found-content-container {
  font-size: 18px;
}

.not-found-content-container p {
  margin-top: 10px;
}

.bio-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: auto;
  background: url(../assets/background.png);
  background-position: top;
  background-repeat: repeat-y;
}

.bio-global-content-container {
  margin-block: 40px;
  margin-inline: 25% !important;
  font-family: "montserrat";
  flex: unset !important;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(4.9px);
  -webkit-backdrop-filter: blur(4.9px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  color: rgb(102, 102, 102);
  font-size: 24px;
}

.bio-title-name-container {
  margin-top: 20px;
  padding-inline: 30px;
  font-family: "JandysDua";
  text-align: left;
  font-size: 100px;
  line-height: 100px;
  color: #f481a6;
  filter: drop-shadow(0px 0px 5px #ffffff);
}

.bio-location-title {
  font-weight: 600;
  color: #f481a6;
}

.bio-sub-title {
  font-weight: 600;
}

.bio-content-container {
  font-size: 18px;
}

.bio-image {
  opacity: 0.9;
}

.bio-image-container {
  margin: auto;
}

.bio-text-container-01 {
  text-align: left;
  margin-top: 60px;
}

.bio-text-container-02 {
  text-align: right;
  margin-top: 0px;
}

@media only screen and (max-width: 991px) {
  .bio-global-content-container {
    margin-inline: 2% !important;
    font-size: 14px;
  }

  .bio-title-name-container {
    font-size: 50px;
  }

  .bio-text-container-01 {
    margin-top: 0px;
  }

  .bio-text-container-02 {
    margin-top: 0px;
  }
}
</style>
