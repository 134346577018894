<template>
  <v-container class="not-found-list-container">
    <BackToTop></BackToTop>
    <v-row class="text-center">
      <v-col cols="12" class="not-found-global-content-container">
        <NavBar></NavBar>
        <v-row>
          <v-col cols="12" lg="12" class="not-found-title-name-container">
            404
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import NavBar from "./NavBar.vue";
import BackToTop from "./BackToTop.vue";
export default {
  name: "RuleBook",
  components: { NavBar, BackToTop },
  data: () => ({}),
  methods: {},
};
</script>

<style>
@font-face {
  font-family: "JandysDua";
  src: local("JandysDua"), url(../fonts/Jandys-dua.otf) format("truetype");
}

.not-found-content-container {
  font-size: 18px;
}

.not-found-content-container p {
  margin-top: 10px;
}

.not-found-list-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: auto;
  background: url(../assets/background.png);
  background-position: center;
  background-repeat: repeat-y;
}

.not-found-global-content-container {
  margin-block: 40px;
  margin-inline: 25% !important;
  font-family: "montserrat";
  flex: unset !important;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(4.9px);
  -webkit-backdrop-filter: blur(4.9px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  color: rgb(102, 102, 102);
  font-size: 24px;
}

.not-found-title-name-container {
  margin-top: 20px;
  padding: 30px;
  font-family: "JandysDua";
  text-align: center;
  font-size: 100px;
  color: #f481a6;
  filter: drop-shadow(0px 0px 5px #ffffff);
}

@media only screen and (max-width: 991px) {
  .not-found-global-content-container {
    margin-inline: 2% !important;
    font-size: 14px;
  }

  .not-found-title-name-container {
    font-size: 50px;
  }
}
</style>
