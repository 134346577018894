<template>
  <v-container class="home-container">
    <v-row class="text-center">
      <v-col cols="12">
        <v-img
          class="logo-image"
          src="../assets/logo.png"
          lazy-load="../assets/logo_lazy.png"
          width="600"
        >
        </v-img>
      </v-col>
    </v-row>
    <v-row class="menu-container text-center">
      <v-col class="menu-container-column" cols="12">
        <v-img
          class="menu-central-image"
          src="../assets/menu_central01.png"
          @mouseover="centralHoverStart"
          @mouseleave="centralHoverEnd"
        >
        </v-img>
        <v-img
          id="menu-additional-image"
          class="menu-additional-image"
          src="../assets/menu_central02.png"
          @mouseover="additionalHoverStart"
          @mouseleave="additionalHoverEnd"
        >
        </v-img>
        <span
          class="menu-button-entry"
          id="menu-button-pricelist"
          @mouseover="buttonHoverStart(3)"
          @mouseleave="buttonHoverEnd(3)"
          @click="pricelistClick"
        >
          cjenik
        </span>
        <span
          class="menu-button-entry"
          id="menu-button-bio"
          @mouseover="buttonHoverStart(4)"
          @mouseleave="buttonHoverEnd(4)"
          @click="bioClick"
        >
          bio
        </span>
        <span
          class="menu-button-entry"
          id="menu-button-gallery"
          @mouseover="buttonHoverStart(5)"
          @mouseleave="buttonHoverEnd(5)"
          @click="galleryClick"
        >
          galerija
        </span>
        <span
          class="menu-button-entry"
          id="menu-button-contact"
          @mouseover="buttonHoverStart(6)"
          @mouseleave="buttonHoverEnd(6)"
          @click="contactClick"
        >
          kontakt
        </span>
        <!-- <span
          class="menu-button-entry"
          id="menu-button-rules"
          @mouseover="buttonHoverStart(7)"
          @mouseleave="buttonHoverEnd(7)"
          @click="rulesClick"
        >
          pravilnik
        </span> -->
      </v-col>
      <v-col cols="12" class="menu-mobile-container-column">
        <v-row @click="toggleMobileMenuDisplay">
          <v-img
            class="menu-mobile-central-image"
            src="../assets/menu_central01.png"
          >
          </v-img
        ></v-row>
        <v-row id="mobileMenuDisplay">
          <ul style="list-style: none">
            <li @click="galleryMobileClick">galerija</li>
            <li @click="bioMobileClick">bio</li>
            <li @click="pricelistMobileClick">cjenik</li>
            <!-- <li @click="rulesMobileClick">pravilnik</li> -->
            <li @click="contactMobileClick">kontakt</li>
          </ul>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "HomeComponent",
  components: {},
  data: () => ({
    mobileMenuDisplay: false,
    menuIsShown: false,
    hideMenu01: true,
    hideMenu02: true,
    hideMenu03: true,
    hideMenu04: true,
    hideMenu05: true,
    hideMenu06: true,
    /* hideMenu07: true, */
  }),
  methods: {
    centralHoverStart() {
      this.hideMenu01 = false;
      this.hideMenu02 = false;
      this.checkToShowMenu();
    },
    centralHoverEnd() {
      this.hideMenu01 = true;
      let vm = this;
      setTimeout(function () {
        vm.checkToHideMenu();
      }, 200);
    },
    additionalHoverStart() {
      this.hideMenu02 = false;
    },
    additionalHoverEnd() {
      this.hideMenu02 = true;
      let vm = this;
      setTimeout(function () {
        vm.checkToHideMenu();
      }, 200);
    },
    buttonHoverStart(buttonNo) {
      this["hideMenu0" + buttonNo] = false;
    },
    buttonHoverEnd(buttonNo) {
      this["hideMenu0" + buttonNo] = true;
      let vm = this;
      setTimeout(function () {
        vm.checkToHideMenu();
      }, 200);
    },
    showMenu() {
      this.menuIsShown = true;
      document.getElementById("menu-additional-image").style.opacity = "0.7";
      document.getElementById("menu-button-pricelist").style.opacity = "1";
      document.getElementById("menu-button-gallery").style.opacity = "1";
      document.getElementById("menu-button-contact").style.opacity = "1";
      /* document.getElementById("menu-button-rules").style.opacity = "1"; */
      document.getElementById("menu-button-bio").style.opacity = "1";
      document.getElementById("menu-button-pricelist").style.cursor = "pointer";
      document.getElementById("menu-button-gallery").style.cursor = "pointer";
      document.getElementById("menu-button-contact").style.cursor = "pointer";
      /* document.getElementById("menu-button-rules").style.cursor = "pointer"; */
      document.getElementById("menu-button-bio").style.cursor = "pointer";
    },
    hideMenu() {
      this.menuIsShown = false;
      document.getElementById("menu-additional-image").style.opacity = "0";
      document.getElementById("menu-button-pricelist").style.opacity = "0";
      document.getElementById("menu-button-gallery").style.opacity = "0";
      document.getElementById("menu-button-contact").style.opacity = "0";
      /* document.getElementById("menu-button-rules").style.opacity = "0"; */
      document.getElementById("menu-button-bio").style.opacity = "0";
      document.getElementById("menu-button-pricelist").style.cursor = "default";
      document.getElementById("menu-button-gallery").style.cursor = "default";
      document.getElementById("menu-button-contact").style.cursor = "default";
      /* document.getElementById("menu-button-rules").style.cursor = "default"; */
      document.getElementById("menu-button-bio").style.cursor = "default";
    },
    checkToHideMenu() {
      if (
        this.hideMenu01 == true &&
        this.hideMenu02 == true &&
        this.hideMenu03 == true &&
        this.hideMenu04 == true &&
        this.hideMenu05 == true &&
        this.hideMenu06 == true /* &&
        this.hideMenu07 == true */
      ) {
        this.hideMenu();
      }
    },
    checkToShowMenu() {
      if (
        this.hideMenu01 == false ||
        this.hideMenu02 == false ||
        this.hideMenu03 == false ||
        this.hideMenu04 == false ||
        this.hideMenu05 == false ||
        this.hideMenu06 == false /* ||
        this.hideMenu07 == false */
      ) {
        this.showMenu();
      }
    },
    pricelistClick() {
      if (this.menuIsShown) {
        this.$router.push("cjenik");
      }
    },
    bioClick() {
      if (this.menuIsShown) {
        this.$router.push("bio");
      }
    },
    galleryClick() {
      if (this.menuIsShown) {
        this.$router.push("galerija");
      }
    },
    contactClick() {
      if (this.menuIsShown) {
        this.$router.push("kontakt");
      }
    },
    rulesClick() {
      if (this.menuIsShown) {
        this.$router.push("pravilnik");
      }
    },
    pricelistMobileClick() {
      this.$router.push("cjenik");
    },
    bioMobileClick() {
      this.$router.push("bio");
    },
    galleryMobileClick() {
      this.$router.push("galerija");
    },
    contactMobileClick() {
      this.$router.push("kontakt");
    },
    rulesMobileClick() {
      this.$router.push("pravilnik");
    },
    toggleMobileMenuDisplay() {
      let height = document.getElementById("mobileMenuDisplay").clientHeight;
      if (height == 0) {
        document.getElementById("mobileMenuDisplay").style.height = "210px";
      } else {
        document.getElementById("mobileMenuDisplay").style.height = "0px";
      }
    },
  },
};
</script>

<style>
.home-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: #f481a6;
  min-height: 100vh;
  overflow: hidden;
}

.work-in-progress-title {
  color: rgb(255, 255, 255);
  font-family: "montserrat";
  font-weight: 200;
  font-size: 30px;
  filter: drop-shadow(0px 0px 5px #7b4254);
}

.logo-image {
  margin-inline: auto;
  text-align: center;
  filter: drop-shadow(0px 0px 10px #301b22);
}

.menu-central-image {
  cursor: pointer;
  margin-inline: auto;
  filter: drop-shadow(0px 0px 5px #150c0f43);
  width: 200px !important;
  position: relative;
  top: 280px;
  z-index: 50;
  animation: pulsate 5s ease-in-out infinite both;
}

.menu-additional-image {
  margin-inline: auto;
  position: relative;
  top: -63px;
  filter: drop-shadow(0px 0px 5px #150c0f43);
  opacity: 0;
  width: 1000px !important;
  z-index: 49;
  transition: opacity 1500ms;
}

@keyframes pulsate {
  0% {
    opacity: 0.9;
    transform: scale(1);
  }
  50% {
    opacity: 0.85;
    filter: drop-shadow(0px 0px 10px #150c0f43);
    transform: scale(1.02);
    transform: rotate(2deg);
  }
  100% {
    opacity: 0.9;
    transform: scale(1);
  }
}
.menu-container {
  position: relative;
  top: -300px;
}

.menu-button-entry {
  opacity: 0;
  cursor: default;
  color: #ffe3ec;
  font-size: 30px;
  font-family: montserrat;
  transition: opacity 1500ms;
  z-index: 51;
  text-shadow: 0 0 5px #f481a6, 0 0 10px #f481a6, 0 0 10px #f481a6,
    0 0 20px #f481a6, 0 0 20px #f481a6, 0 0 20px #f481a6, 0 0 20px #f481a6;
}

.menu-button-entry:hover {
  color: #eb5f8b;
}

#menu-button-pricelist {
  position: relative;
  top: -280px;
  left: -150px;
}

#menu-button-bio {
  position: relative;
  top: -200px;
  left: -100px;
}
#menu-button-gallery {
  position: relative;
  top: -280px;
  left: 270px;
}

#menu-button-contact {
  position: relative;
  top: -200px;
  left: 30px;
}

#menu-button-rules {
  position: relative;
  top: -280px;
  left: 130px;
}

.menu-mobile-container-column {
  display: none;
}

#mobileMenuDisplay {
  font-family: "montserrat";
  height: 0px;
  transition: height 300ms;
  overflow: hidden;
  justify-content: center;
  margin-top: 30px;
  font-size: 20px;
  color: rgba(255, 255, 255, 0.637);
}

#mobileMenuDisplay li {
  margin-top: 10px;
  cursor: pointer;
}

#mobileMenuDisplay li:hover {
  cursor: pointer;
  color: rgb(201, 201, 201);
}

.menu-mobile-central-image {
  cursor: pointer;
  height: 50px;
  animation: pulsate2 2s ease-in-out infinite both;
}

@keyframes pulsate2 {
  0% {
    opacity: 0.9;
    transform: scale(1);
  }
  50% {
    opacity: 0.85;
    filter: drop-shadow(0px 0px 10px #150c0f43);
    transform: scale(1.2);
    transform: rotate(2deg);
  }
  100% {
    opacity: 0.9;
    transform: scale(1);
  }
}

@media only screen and (max-width: 991px) {
  .home-container {
    padding-top: 80px;
  }

  .menu-container-column {
    display: none;
  }

  .menu-mobile-container-column {
    position: absolute;
    top: 40vh;
    display: block;
  }
}
</style>
